import COLORS from 'const/colors';

const PurpsTickMark = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M14 3.33337L5.75 12L2 8.06065"
      stroke={COLORS.TEXT.PURPS_3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PurpsTickMark;
